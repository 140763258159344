import React, { useState, useEffect } from 'react';

const AssignESPId = () => {
  const [macAddresses, setMacAddresses] = useState([]);
  const [selectedMac, setSelectedMac] = useState('');
  const [espId, setEspId] = useState('');
  const [message, setMessage] = useState('');

  // Fetch MAC addresses on component load
  useEffect(() => {
    const fetchMacAddresses = async () => {
         const API_URL = process.env.REACT_APP_API_URL;
         const token = localStorage.getItem('token');


      try {
          const response = await fetch(`${API_URL}/get_mac_addresses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch MAC addresses');
        }
        const data = await response.json();
        setMacAddresses(data);
      } catch (error) {
        setMessage(`Error fetching MAC addresses: ${error.message}`);
      }
    };

    fetchMacAddresses();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    if (!selectedMac) {
      setMessage('Please select a MAC address.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/assign_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ macAddress: selectedMac, espId }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong');
      }

      setMessage(data.message);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-semibold text-center mb-5">Assign ESP ID to MAC Address</h2>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {/* Dropdown to select MAC address */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="macAddress">
            Select MAC Address
          </label>
          <select
            id="macAddress"
            value={selectedMac}
            onChange={(e) => setSelectedMac(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="" disabled>Select a MAC Address</option>
            {macAddresses.map((mac, index) => (
              <option key={index} value={mac.macAddress}>
                {mac.macAddress}
              </option>
            ))}
          </select>
        </div>

        {/* Input for ESP ID */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="espId">
            ESP ID
          </label>
          <input
            type="text"
            id="espId"
            value={espId}
            onChange={(e) => setEspId(e.target.value)}
            placeholder="Enter ESP ID"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        {/* Submit button */}
        <div className="flex items-center justify-between">
          <button
            type="submit"n
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Assign ID
          </button>
        </div>

        {/* Message Display */}
        {message && (
          <div className="mt-4 text-center">
            <p className={`text-${message.includes('Error') ? 'red' : 'green'}-500`}>{message}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default AssignESPId;
