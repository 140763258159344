import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the ID from the URL

const TariffsForm = () => {
  const { espId } = useParams(); // Extract espId from the URL
  const [coldTariffs, setColdTariffs] = useState('');
  const [freshTariffs, setFreshTariffs] = useState('');
  const [coldCalb, setColdCalb] = useState('');
  const [freshCalb, setFreshCalb] = useState('');
  const [previousColdTariffs, setPreviousColdTariffs] = useState('');
  const [previousFreshTariffs, setPreviousFreshTariffs] = useState('');
  const [previousColdCalb, setPreviousColdCalb] = useState('');
  const [previousFreshCalb, setPreviousFreshCalb] = useState('');
  const [newColdTariffs, setNewColdTariffs] = useState('');
  const [newFreshTariffs, setNewFreshTariffs] = useState('');
  const [newColdCalb, setNewColdCalb] = useState('');
  const [newFreshCalb, setNewFreshCalb] = useState('');
  const [message, setMessage] = useState('');

  // Fetch existing tariffs when the component loads
  useEffect(() => {
    const fetchTariffs = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(`${API_URL}/machine_settings/${espId}`);
        const data = await response.json();
        if (response.ok) {
          setPreviousColdTariffs(data.cold_tariffs);
          setPreviousFreshTariffs(data.fresh_tariffs);
          setPreviousFreshCalb(data.fresh_calb);
          setPreviousColdCalb(data.cold_calb);

        } else {
          setMessage(data.message);
        }
      } catch (error) {
        setMessage(`Error fetching tariffs: ${error.message}`);
      }
    };

    if (espId) {
      fetchTariffs(); // Fetch tariffs only if espId is available
    }
  }, [espId]);

  // Handle form submission to insert new tariffs
  const handleSubmit = async (e) => {
    e.preventDefault();
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${API_URL}/insert_tariffs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ espId, coldTariffs, freshTariffs, coldCalb, freshCalb}),
      });
      const data = await response.json();
      setMessage(response.ok ? 'Tariffs inserted successfully!' : data.message);
      if (response.ok) {
        setNewColdTariffs(coldTariffs);
        setNewFreshTariffs(freshTariffs);
        setNewColdCalb(coldCalb);
        setNewFreshCalb(freshCalb);
        // Reset the input fields after successful submission
        setColdTariffs('');
        setFreshTariffs('');
        setColdCalb('');
        setFreshCalb('');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Clear input fields
  const handleClear = () => {
    setColdTariffs('');
    setFreshTariffs('');
    setMessage('');
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-semibold text-center mb-5">Insert New Tariffs</h2>

      <div className="bg-gray-100 p-4 rounded mb-4">
        <h3 className="text-lg font-semibold">Previous Tariffs</h3>
        <p><strong>Cold Tariffs:</strong> {previousColdTariffs}</p>
        <p><strong>Fresh Tariffs:</strong> {previousFreshTariffs}</p>
        <p><strong>Cold Calb:</strong>{previousColdCalb}</p>
        <p><strong>Fresh Calb:</strong>{previousFreshCalb}</p>
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label htmlFor="coldTariffs" className="block text-gray-700 text-sm font-bold mb-2">Cold Tariffs</label>
          <input
            type="text"
            id="coldTariffs"
            value={coldTariffs}
            onChange={(e) => setColdTariffs(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="freshTariffs" className="block text-gray-700 text-sm font-bold mb-2">Fresh Tariffs</label>
          <input
            type="text"
            id="freshTariffs"
            value={freshTariffs}
            onChange={(e) => setFreshTariffs(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="freshCalb" className="block text-gray-700 text-sm font-bold mb-2">Fresh Calb</label>
          <input
            type="text"
            id="freshCalb"
            value={freshCalb}
            onChange={(e) => setFreshCalb(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="coldCalb" className="block text-gray-700 text-sm font-bold mb-2">Cold Calb</label>
          <input
            type="text"
            id="coldCalb"
            value={coldCalb}
            onChange={(e) => setColdCalb(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Insert Tariffs
          </button>
          <button
            type="button"
            onClick={handleClear}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none"
          >
            Clear
          </button>
        </div>
      </form>

      {message && <p className="text-center mt-4">{message}</p>}

      {/* Display newly inserted tariffs */}
      {newColdTariffs && newFreshTariffs && (
        <div className="bg-green-100 p-4 rounded mt-4">
          <h3 className="text-lg font-semibold">Newly Inserted Tariffs</h3>
          <p><strong>Cold Tariffs:</strong> {newColdTariffs}</p>
          <p><strong>Fresh Tariffs:</strong> {newFreshTariffs}</p>
          <p><strong>Cold Calb:</strong>{newColdCalb}</p>
          <p><strong>Fresh Calb:</strong>{newFreshCalb}</p>
        </div>
      )}
    </div>
  );
};

export default TariffsForm;
