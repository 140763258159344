 

import React, { useEffect, useState } from 'react';

function CardInfoSummary() {
  const [cardSummary, setCardSummary] = useState({});
  const [selectedMachine, setSelectedMachine] = useState(null); // Track selected machine

  useEffect(() => {
    const fetchCardSummary = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/cards/summary`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        console.log('Card Summary:', data);

        if (response.ok) {
          setCardSummary(data);
        } else {
          alert('Failed to fetch card summary: ' + data.error);
        }

      } catch (error) {
        console.error('Error fetching card summary:', error);
        alert('An error occurred while fetching card summary.');
      }
    };

    fetchCardSummary();
  }, []);

  const handleMachineClick = (esp32Id) => {
    setSelectedMachine(esp32Id); // Set selected machine when clicked
  };

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-gray-800 text-center">Card Information Summary</h1>

      {/* Display a message if no machines are found */}
      {Object.keys(cardSummary).length === 0 ? (
        <p className="text-gray-600 text-center">No machines found for this user.</p>
      ) : (
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.entries(cardSummary).map(([esp32Id, totalCards]) => (
            <li
              key={esp32Id}
              className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 hover:shadow-2xl hover:border-blue-400 transition-all duration-300 transform hover:scale-105"
            >
              <button
                onClick={() => handleMachineClick(esp32Id)} // Trigger card pad on button click
                className="block text-xl font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-200"
              >
                Machine ID: {esp32Id}
              </button>
              <p className="text-gray-700 mt-2">Total Cards: {totalCards}</p>
            </li>
          ))}
        </ul>
      )}

      {/* Show the card pad if a machine is selected */}
      {selectedMachine && <CardPad esp32Id={selectedMachine} />}
    </div>
  );
}

function CardPad({ esp32Id }) {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null); // Track selected card
  const [transactions, setTransactions] = useState([]); // Track transactions for selected card

  useEffect(() => {
    const fetchCards = async () => {
      if (!esp32Id) return;

      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/cards/${esp32Id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        console.log('Fetched cards:', data);

        if (response.ok) {
          setCards(data);
        } else {
          alert('Failed to fetch machine details: ' + data.error);
        }
      } catch (error) {
        console.error('Error fetching machine details:', error);
        alert('An error occurred while fetching machine details.');
      }
    };

    fetchCards();
  }, [esp32Id]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!selectedCard) return;

      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/transactions/${selectedCard.card_id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        console.log('Fetched transactions:', data);

        if (response.ok) {
          setTransactions(data);
        } else {
          alert('Failed to fetch transactions: ' + data.error);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
        alert('An error occurred while fetching transactions.');
      }
    };

    fetchTransactions();
  }, [selectedCard]);

  const handleCardClick = (card) => {
    setSelectedCard(card.id === selectedCard?.id ? null : card); // Toggle card selection
  };

  return (
    <div className="p-8 mt-8 bg-gray-50 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-gray-800 text-center">Registered Cards for Machine ID: {esp32Id}</h2>

      {/* Card List */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
        {cards.length > 0 ? (
          cards.map((card) => (
            <div
              key={card.id}
              className={`bg-white p-6 rounded-lg shadow-lg border border-gray-200 cursor-pointer transition-all duration-300 transform hover:scale-105 ${selectedCard?.id === card.id ? 'border-blue-600 shadow-xl' : 'hover:border-blue-400'}`}
              onClick={() => handleCardClick(card)}
            >
              <h3 className="text-xl font-semibold mb-3 text-gray-800">Card ID: {card.card_id}</h3>
              <p className="text-gray-600">Name: {card.card_holder_first_name} {card.card_holder_last_name}</p>
              <p className="text-gray-600">Mobile No: {card.phoneNumber}</p>
              <p className="text-gray-600">Date Registered: {new Date(card.date_registered).toLocaleDateString()}</p>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center py-4 text-xl text-gray-500">No registered cards found.</div>
        )}
      </div>

      {/* Display selected card details */}
      {selectedCard && (
        <div className="mt-10 p-6 bg-white rounded-lg shadow-lg border border-gray-200">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Selected Card Details</h3>
          <p className="text-xl text-gray-700">Card ID: {selectedCard.card_id}</p>
          <p className="text-xl text-gray-700">Name: {selectedCard.card_holder_first_name} {selectedCard.card_holder_last_name}</p>
          <p className="text-xl text-gray-700">Mobile No: {selectedCard.phoneNumber}</p>
          <p className="text-xl text-gray-700">Date Registered: {new Date(selectedCard.date_registered).toLocaleDateString()}</p>

          {/* Transaction Table */}
          <h4 className="text-xl font-semibold mt-6 text-gray-800">Transaction History</h4>
          {transactions.length > 0 ? (
            <div className="overflow-x-auto mt-4">
              <table className="min-w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-blue-600 text-white">
                    <th className="px-4 py-3 text-left text-sm sm:text-base">Transaction ID</th>
                    <th className="px-4 py-3 text-left text-sm sm:text-base">Amount</th>
                    <th className="px-4 py-3 text-left text-sm sm:text-base">Date</th>
                    <th className="px-4 py-3 text-left text-sm sm:text-base">Card Type</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={transaction.id} className="border-b hover:bg-gray-100">
                      <td className="px-4 py-3 text-sm sm:text-base">{transaction.transaction_id}</td>
                      <td className="px-4 py-3 text-sm sm:text-base">{transaction.amount}</td>
                      <td className="px-4 py-3 text-sm sm:text-base">{new Date(transaction.created_at).toLocaleDateString()}</td>
                      <td className="px-4 py-3 text-sm sm:text-base">{transaction.card_type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="mt-4 text-gray-500">No transactions found for this card.</p>
          )}

        </div>
      )}
    </div>
  );
}

export default CardInfoSummary;
