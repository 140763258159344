import React from 'react'
import Navbar from './Navbar'

function RemoveAttendant() {
  return (
    <div>
        <Navbar />
    </div>
  )
}

export default RemoveAttendant