import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

const MachineSummary = ({ machine, monthlyData }) => {
  const navigate = useNavigate(); // Call useNavigate unconditionally

  // Check if machine data is available
  if (!machine) {
    return <div className="text-red-500">Error: Machine data is not available.</div>;
  }

  const { machineId, coinBased = {}, cardBased = {} } = machine;

  const machineMonthlyData = monthlyData.find((m) => m.machineId === machineId) || { coinBased: {}, cardBased: {} };

  // Determine daily data or fallback to monthly data if daily is empty
  const dailyCoinBased = {
    priceCollected: coinBased.dailyPriceCollected || 0,
    volumeSold: coinBased.volumeSold || 0,
  };

  const dailyCardBased = {
    priceCollected: cardBased.dailyPriceCollected || 0,
    volumeSold: cardBased.volumeSold || 0,
  };

  const handleCoinClick = () => navigate(`/machine/${machineId}/coin`);
  const handleCardClick = () => navigate(`/machine/${machineId}/card`);

  return (
    <div className="machine-summary p-4 border border-gray-300 rounded-lg shadow-lg mb-6 bg-white hover:shadow-xl transition-shadow duration-200 hover:border-blue-400">
      <p className="text-2xl font-semibold text-center text-gray-800 mb-4">{machineId}</p>

      {/* Daily Activity Summary */}
      {dailyCoinBased.priceCollected > 0 || dailyCardBased.priceCollected > 0 ? (
        <>
          <p className="text-xl font-semibold text-center text-green-600 mb-4">
            <Typewriter
              options={{
                strings: ['Daily Activity Summary.'],
                autoStart: true,
                loop: true,
              }}
            />
          </p>

          {/* Coin-based Section */}
          <div
            className="mode-summary mb-4 p-4 bg-gradient-to-l from-blue-500 to-gray-700 text-white rounded-lg transition-transform duration-300 transform hover:scale-105 cursor-pointer"
            onClick={handleCoinClick}
          >
            <h3 className="text-lg font-medium mb-2">Coin-based</h3>
            <p>Price Collected : <span className="font-bold">{dailyCoinBased.priceCollected}</span></p>
            <p>Volume Sold : <span className="font-bold">{dailyCoinBased.volumeSold}</span></p>
          </div>

          {/* Card-based Section */}
          <div
            className="mode-summary p-4 bg-gradient-to-l from-blue-500 to-gray-700 text-white rounded-lg transition-transform duration-300 transform hover:scale-105 cursor-pointer"
            onClick={handleCardClick}
          >
            <h3 className="text-lg font-medium mb-2">Card-based</h3>
            <p>Price Collected : <span className="font-bold">{dailyCardBased.priceCollected}</span></p>
            <p>Volume Sold : <span className="font-bold">{dailyCardBased.volumeSold}</span></p>
          </div>
        </>
      ) : null}

      {/* Monthly Activity Summary */}
      <p className="text-xl font-semibold text-center text-green-600 mt-10 mb-4">
        <Typewriter
          options={{
            strings: ['Monthly Activity Summary.'],
            autoStart: true,
            loop: true,
          }}
        />
      </p>

      {/* Monthly Coin-based Section */}
      <div
        className="mode-summary mb-4 p-4 bg-gradient-to-l from-blue-500 to-gray-700 text-white rounded-lg transition-transform duration-300 transform hover:scale-105 cursor-pointer"
        onClick={handleCoinClick}
      >
        <h3 className="text-lg font-medium mb-2">Coin-based</h3>
        <p>Price Collected : <span className="font-bold">{machineMonthlyData.coinBased.monthlyPriceCollected || 0}</span></p>
        <p>Volume Sold : <span className="font-bold">{machineMonthlyData.coinBased.volumeSold || 0}</span></p>
      </div>

      {/* Monthly Card-based Section */}
      <div
        className="mode-summary p-4 bg-gradient-to-l from-blue-500 to-gray-700 text-white rounded-lg transition-transform duration-300 transform hover:scale-105 cursor-pointer"
        onClick={handleCardClick}
      >
        <h3 className="text-lg font-medium mb-2">Card-based</h3>
        <p>Monthly Price Collected : <span className="font-bold">{machineMonthlyData.cardBased.monthlyPriceCollected || 0}</span></p>
        <p>Volume Sold : <span className="font-bold">{machineMonthlyData.cardBased.volumeSold || 0}</span></p>
      </div>
    </div>
  );
};

export default MachineSummary;
