import React, { useEffect, useState } from 'react';

import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

function AddESP32() {
  const [esp32Id, setEsp32Id] = useState('');
  const [userId, setUserId] = useState('');
  const [machine_location, setMachineLocation] = useState('');
  const [message, setMessage] = useState('');
  const [esp32List, setEsp32List] = useState([]);
  const [user, setUser] = useState(null);
  const [espDevices, setEspDevices] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({ identifier: '', password: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeTab, setActiveTab] = useState('info');
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUserData = async () => {
        const API_URL = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        if (!token) {
          console.error('No token found');
          return;
        }

        try {
          const userResponse = await fetch(`${API_URL}/user/info`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (!userResponse.ok) {
            const errorText = await userResponse.text();
            console.error('Failed to fetch user info:', errorText);
            return;
          }

          const userData = await userResponse.json();
          setUser(userData);
          setUserId(userData.userId);

          const espResponse = await fetch(`${API_URL}/user/esp32-ids`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (!espResponse.ok) {
            const errorText = await espResponse.text();
            console.error('Failed to fetch ESP devices:', errorText);
            return;
          }

          const devices = await espResponse.json();
          setEspDevices(devices);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchUserData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchESP32List = async () => {
      const API_URL = process.env.REACT_APP_API_URL;

      const token = localStorage.getItem('token');

      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/admin/get/esp32-ids`,{
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEsp32List(data);
      } catch (error) {
        console.error('Error fetching ESP32 list:', error);
      }
    };

    fetchESP32List();
  }, []);

  useEffect(() => {
    const fetchAssignedESP32List = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('No token found');
        return;
      }

      
      try {
        const response = await fetch(`${API_URL}/get/user/assigned/esp32-ids`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const assignedData = await response.json();
        setEsp32List(prevList => prevList.filter(id => !assignedData.includes(id)));
      } catch (error) {
        console.error('Error fetching assigned ESP32 list:', error);
      }
    };

    fetchAssignedESP32List();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginCredentials),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      localStorage.setItem('token', result.token);
      setIsLoggedIn(true);
      setMessage('Login successful');
    } catch (error) {
      console.error('Error logging in:', error);
      setMessage('Login failed');
    }
  };

  const handleAddDevice = async (e) => {
    e.preventDefault();
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/add/user/devices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
          esp32Id,
          machine_location,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setMessage(result.message);

    } catch (error) {
      console.error('Error Adding Device', error);
      setMessage('Error Adding Device');
    }
  };

  const handleLogout = () => {
    // Clear token and other relevant data
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    // Redirect to login page
    navigate('/login');
  };

return (
  <div>
    <Navbar />
    <div className=" m-20 flex justify-center">
      {!isLoggedIn ? (
        <div className="w-full max-w-sm bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Login</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
            <label htmlFor="identifier" className="block text-gray-700">
                Email or Phone Number
              </label>
              <input
                type="text"
                id="identifier"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
                value={loginCredentials.identifier}
                onChange={(e) => setLoginCredentials({...loginCredentials, identifier: e.target.value})}
                required
                autoComplete="username"
               />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Password:</label>
              <input
                type="password"
                id="password"
                value={loginCredentials.password}
                onChange={(e) => setLoginCredentials({ ...loginCredentials, password: e.target.value })}
                required
                autoComplete="current-password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Login
            </button>
          </form>
          {message && <p className="mt-4 text-red-500 text-center">{message}</p>}
        </div>
      ) : (
        <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">Account Management</h1>

          {/* Tabs */}
          <div className="flex justify-center space-x-4 mb-6">
            <button
              onClick={() => setActiveTab('info')}
              className={`px-4 py-2 rounded-lg ${activeTab === 'info' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              User Information
            </button>
            <button
              onClick={() => setActiveTab('devices')}
              className={`px-4 py-2 rounded-lg ${activeTab === 'devices' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Add Devices
            </button>
          </div>

          {/* Content */}
          {activeTab === 'info' && (
            <div>
              {user && (
                <div className="mb-6">
                  <h2 className="text-xl font-semibold text-gray-700 mb-4">User Information</h2>
                  <p className="text-gray-700"><strong>Name:</strong> {user.firstName} {user.lastName}</p>
                  <p className="text-gray-700"><strong>Username:</strong> {user.username}</p>
                  <p className="text-gray-700"><strong>Email:</strong> {user.email}</p>
                  <p className="text-gray-700"><strong>Phone:</strong> {user.phoneNumber}</p>
                  <p className="text-gray-700"><strong>Location:</strong> {user.location}</p>
                </div>
              )}
              <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-4">Assigned ESP Devices</h2>
              <ul className="space-y-2">
                {espDevices.length > 0 ? (
                  espDevices.map((espDevice) => (
                    <li key={espDevice.esp32Id} className="bg-blue-50 p-4 rounded-lg text-blue-600">
                      <strong>ID:</strong> {espDevice.esp32Id} <br />
                      <strong>Location:</strong> {espDevice.machine_location}
                    </li>
                  ))
                ) : (
                  <p className="text-center text-gray-600">No ESP devices assigned</p>
                )}
              </ul>
            </div>
          )}

          {activeTab === 'devices' && (
            <div>
              <h2 className="text-xl font-semibold text-gray-700 mb-4">Add Devices</h2>
              <form onSubmit={handleAddDevice}>
                <div className="mb-4">
                  <label className="block text-gray-700">Choose Device ID:</label>
                  <select
                    value={esp32Id}
                    onChange={(e) => setEsp32Id(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  >
                    <option value="" disabled>Select Device ID</option>
                    {esp32List.map(id => (
                      <option key={id} value={id}>{id}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Machine Location:</label>
                  <input
                    type="text"
                    value={machine_location}
                    onChange={(e) => setMachineLocation(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition duration-200"
                >
                  Add Device
                </button>
              </form>
              {message && <p className="mt-4 text-green-500 text-center">{message}</p>}
            </div>
          )}

          
          <button
          onClick={handleLogout} // Add your logout logic here
          className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600 transition duration-200 mt-6"
           >
            Logout
          </button>

        </div>
      )}
    </div>
  </div>
);


}

export default AddESP32;
