import React from 'react'
import Navbar from './Navbar'
import AssignESPId from './AssignESPId'
 
// import AddESP32 from './AddESP32'
 


function MainDashboard() {
  return (
    <div> 
       <Navbar /> 
        {/* <AddESP32/> */}
        <div>
        <AssignESPId />
        </div>
    </div>
  )
}

export default MainDashboard