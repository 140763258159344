import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function Login() {
  const [identifier, setIdentifier] = useState(''); // Can be email or phone number
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();  // Hook to navigate between routes

  const handleLogin = async (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ identifier, password }), // Send identifier instead of email
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Login failed:', errorText);
        setMessage(`Login failed: ${errorText}`);
        return;
      }

      // re

      const result = await response.json();
      console.log('Login successful:', result);

      // Check token and navigate based on role
      const decodedToken = jwtDecode(result.token);

      // Calculate expiration time based on the server response
      const expiryTime = Date.now() + (20 * 60 * 1000); // 20 minutes from now

      // Store token and expiration time in localStorage
      localStorage.setItem('token', result.token);
      localStorage.setItem('role', decodedToken.role);
      localStorage.setItem('tokenExpiry', expiryTime);

      if (decodedToken.role === 'admin') {
        // Navigate to admin dashboard
        navigate('/maindashboard');
      } else if (decodedToken.role === 'manager') {
        // Navigate to user dashboard
        navigate('/manager/dashboard');
      } else if (decodedToken.role === 'attendant') {
        // Navigate to user dashboard
        navigate('/att/manager/dashboard');
      }
       else {
        navigate('/login');
      }
      
    } catch (error) {
      console.error('Error logging in:', error);
      setMessage('Error logging in');
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col">
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-2xl font-bold text-black mb-6 text-center">Log In</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="identifier" className="block text-gray-700">
                Email or Phone Number
              </label>
              <input
                type="text"
                id="identifier"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                required
                autoComplete="username"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Log In
            </button>
            {message && (
              <p className="mt-4 text-red-500 text-center">{message}</p>
            )}
          </form>
        </div>
      </div>
      <footer className="bg-gray-800 py-4 text-center">
        <p className="text-gray-400 text-sm">
          © {new Date().getFullYear()} Water Vending Solutions. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default Login;
