import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';

const ManagerAprove = () => {
  const [floatRequests, setFloatRequests] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchFloatRequests = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/manager/float-requests`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          setMessage(`Error: ${errorText}`);
          return;
        }

        const requests = await response.json();
        setFloatRequests(requests);
      } catch (error) {
        setMessage('Error fetching float requests');
        console.error('Error:', error);
      }
    };

    fetchFloatRequests();
  }, []);

  const handleAssignFloat = async (attendantId, floatAmount) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_URL}/manager/assign-float`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ attendantId, amount: floatAmount }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        setMessage(`Error: ${errorText}`);
        return;
      }

      setMessage('Float assigned successfully');
      // Update state to reflect the float request has been handled
      setFloatRequests((prevRequests) =>
        prevRequests.filter((request) => request.attendantId !== attendantId)
      );
    } catch (error) {
      setMessage('Error assigning float');
      console.error('Error:', error);
    }
  };

  return (
    <div>
        <Navbar />   
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Float Approval </h1>
      {message && <p className="mb-4">{message}</p>}
      {floatRequests.length > 0 ? (
        <ul>
          {floatRequests.map((request) => (
            <li key={request.attendantId} className="mb-4">
              <div className="p-4 border rounded">
                <p><strong>Attendant ID:</strong> {request.attendantId}</p>
                <p><strong>Requested Float:</strong> {request.requestedAmount}</p>
                <button
                  onClick={() => handleAssignFloat(request.attendantId, request.requestedAmount)}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Assign Float
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No float requests pending</p>
      )}
    </div>
    </div>
  );
};

export default ManagerAprove;
