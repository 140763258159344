import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the ID from the URL

const Wcredentials = () => {
  const { espId } = useParams(); // Extract the espId from the URL
  const [ssid, setSsid] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  // Fetch Wi-Fi credentials when the component loads
  useEffect(() => {
    const fetchWifiCredentials = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(`${API_URL}/get_wifi_credentials/${espId}`);
        const data = await response.json();
        if (response.ok) {
          setSsid(data.ssid);
          setPassword(data.password);
        } else {
          setMessage(data.message);
        }
      } catch (error) {
        setMessage(`Error fetching Wi-Fi credentials: ${error.message}`);
      }
    };

    if (espId) {
      fetchWifiCredentials(); // Fetch Wi-Fi credentials only if espId is available
    }
  }, [espId]);

  // Handle form submission to update Wi-Fi credentials
  const handleSubmit = async (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(`${API_URL}/update_wifi_credentials`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ espId, ssid, password }),
      });
      const data = await response.json();
      setMessage(response.ok ? 'Wi-Fi credentials updated successfully!' : data.message);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Handle clearing the fields and updating the table
  const handleClear = async () => {
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      // Set the ssid and password to empty and update in the database
      const response = await fetch(`${API_URL}/update_wifi_credentials`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ espId, ssid: '', password: '' }), // Send empty values
      });
      const data = await response.json();

      if (response.ok) {
        setSsid(''); // Clear local state
        setPassword(''); // Clear local state
        setMessage('Wi-Fi credentials cleared successfully!');
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage(`Error clearing Wi-Fi credentials: ${error.message}`);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-semibold text-center mb-5">Update Wi-Fi Credentials for {espId}</h2>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label htmlFor="ssid" className="block text-gray-700 text-sm font-bold mb-2">SSID</label>
          <input
            type="text"
            id="ssid"
            value={ssid}
            onChange={(e) => setSsid(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
          <input
            type="text"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Update Wi-Fi
          </button>

          <button
            type="button"
            onClick={handleClear}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Clear
          </button>
        </div>
      </form>

      {message && <p className="text-center mt-4">{message}</p>}
    </div>
  );
};

export default Wcredentials;
