import React from 'react';

function AttendantPersonalInfo({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  location,
  setLocation,
  message,
  setMessage // Added for setting the validation message
}) {
  const validateForm = () => {
    if (!firstName || !lastName || !username || !phoneNumber) {
      setMessage('Please fill in all required fields.');
      return false;
    }
    setMessage(''); // Clear the message if validation passes
    return true;
  };

  const handleNext = () => {
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl w-full">
        <h2 className="text-2xl font-bold text-black mb-6 text-center">Create New Attendant - Personal Info</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="firstname" className="block text-gray-700">First Name:</label>
            <input
              type="text"
              id="firstname"
              value={firstName}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder='Your Attendant First Name.'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-gray-700">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder='Your Attendant Last Name.'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700">Company Name:</label>
            <input
              type="text"
              id="username"
              value={username}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder='Your Company Name.'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Optional'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-gray-700">Phone Number:</label>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              placeholder='+255'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="location" className="block text-gray-700">Current Location:</label>
            <input
              type="text"
              id="location"
              value={location}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </div>
          {message && (
            <p className="mt-2 text-red-500">{message}</p>
          )}
          <button
            type="button"
            onClick={handleNext}
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Next
          </button>
        </form>
        <p className="mt-2 text-red-500 text-center">All fields are required here, Except Email can be empty.</p>
      </div>
    </div>
  );
}

export default AttendantPersonalInfo;

