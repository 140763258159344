import React from 'react';

function AttendantAccountInfo({
  prevStep,
  handleUserRegistration,
  esp32Id,
  setEsp32Id,
  esp32List,
  machine_location,
  setMachineLocation,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  message
}) {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl w-full">
        <h2 className="text-2xl font-bold text-black mb-6 text-center">Create New Attendant - Account Info</h2>
        <form onSubmit={handleUserRegistration}>
          <div className="mb-4">
            <label className="block text-gray-700">Choose Device ID:</label>
            <select
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              value={esp32Id}
              onChange={(e) => setEsp32Id(e.target.value)}
              required
            >
              <option value="" disabled>Select ID of Device</option>
              {esp32List.map((id) => (
                <option key={id.esp32Id} value={id.esp32Id}>{id.esp32Id}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="machine_location" className="block text-gray-700">Machine Location:</label>
            <input
              type="text"
              id="machine_location"
              value={machine_location}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setMachineLocation(e.target.value)}
              required
              placeholder='Location.'

            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder='First Password.'

            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-gray-700">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder='Your First Passoword.'

            />
          </div>
          {message && (
            <p className="mt-2 text-red-500">{message}</p>
          )}
          <div className="flex justify-between">
            <button
              type="button"
              onClick={prevStep}
              className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition duration-200"
            >
              Back
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200"
            >
              Register
            </button>
          </div>
        </form>
        <p className="mt-2 text-red-500 text-center">All fields are required here</p>
      </div>
    </div>
  );
}

export default AttendantAccountInfo;
