import React from "react";
import { useNavigate } from "react-router-dom";

function Welcome() {
  const navigate = useNavigate();

  // Navigate to the Registered User Login page
  const handleRegisteredUser = () => {
    navigate('/login');
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-cover bg-center" style={{ backgroundImage: "url('image.png')" }}>
      
      <div className="text-center p-6 bg-black bg-opacity-50 rounded-lg shadow-lg max-w-lg">
        <h1 className="text-4xl font-bold text-cyan-600 mb-4">
          Welcome to OakTech Solutions
        </h1>
        <p className="text-lg text-white mb-6">
          Leading Innovators in Water Vending and Dispensing Machine Design
        </p>
        <p className="text-white mb-8">
          At OakTech Solutions, we specialize in creating state-of-the-art water vending and dispensing machines that are efficient, reliable, and environmentally friendly. Join us in making clean water accessible to everyone.
        </p>
        <div className="flex justify-center">
          <button
            onClick={handleRegisteredUser}
            className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-200"
          >
            Continue
          </button>
        </div>
      </div>
      <footer className="mt-32  text-white px-10
       bg-black opacity-50 rounded-lg">
        © 2024 OakTech Solutions. All Rights Reserved.
      </footer>
    </div>
  );
}

export default Welcome;
