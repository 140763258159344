
import React, { useState, useEffect } from 'react';
import RegisterPersonalInfo from './RegisterPersonalInfo';
import RegisterAccountInfo from './RegisterAccountInfo';
import { v4 as uuidv4 } from 'uuid';
import Navbar from './Navbar';

function Register() {
  const [step, setStep] = useState(1);
  const [userId] = useState(uuidv4());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState('');
  const [esp32Id, setEsp32Id] = useState('');
  const [esp32List, setEsp32List] = useState([]);
  const [machine_location, setMachineLocation] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  // Function to move to the next step
  const nextStep = () => setStep(step + 1);

  // Function to move to the previous step
  const prevStep = () => setStep(step - 1);

  // Fetch the list of ESP32 IDs on component mount
  useEffect(() => {
    const fetchESP32List = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${API_URL}/admin/get/esp32-ids`,{
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEsp32List(data);
      } catch (error) {
        console.error('Error fetching ESP32 list:', error);
      }
    };

    fetchESP32List();
  }, []);

  // Fetch the list of assigned ESP32 IDs to filter out
  useEffect(() => {
    const fetchAssignedESP32List = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/get/user/assigned/esp32-ids`,{
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const assignedData = await response.json();
        setEsp32List((prevList) => prevList.filter((id) => !assignedData.includes(id)));
      } catch (error) {
        console.error('Error fetching assigned ESP32 list:', error);
      }
    };

    fetchAssignedESP32List();
  }, []);

  // Handle user registration
  const handleUserRegistration = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    if (!esp32Id) {
      setMessage('Please select an ESP32 ID');
      return;
    }

    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_URL}/admin/register-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
          firstName,
          lastName,
          username,
          email,
          password,
          phoneNumber,
          location,
          esp32Id,
          machine_location,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setMessage(result.message);
    } catch (error) {
      console.error('Error registering user:', error);
      setMessage('Error registering user');
    }
  };

  return (
    <>
    <div>
      < Navbar />
    
      {step === 1 ? (
        <RegisterPersonalInfo
          nextStep={nextStep}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          username={username}
          setUsername={setUsername}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          location={location}
          setLocation={setLocation}
          message={message}
        />
      ) : (
        <RegisterAccountInfo
          prevStep={prevStep}
          handleUserRegistration={handleUserRegistration}
          esp32Id={esp32Id}
          setEsp32Id={setEsp32Id}
          esp32List={esp32List}
          machine_location={machine_location}
          setMachineLocation={setMachineLocation}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          message={message}
        />
      )}
      </div>
      <footer className="bg-gray-800 py-4 text-center mt-auto">
        <p className="text-gray-400 text-sm">
          &copy; {new Date().getFullYear()} Water Vending Solutions. All rights reserved.
        </p>
      </footer>
    </>
  );
}

export default Register;
