import React from 'react'
import Navbar from './Navbar'
import MachineActivity from './MachineActivity'


function UserDashboard() {
  return (
    <div>
      <Navbar />
      <div>
       <MachineActivity />
      </div>
     
    </div>
  )
}

export default UserDashboard