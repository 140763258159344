 
import React, { useEffect, useState, useCallback } from 'react'; // Import useCallback
import { useNavigate } from 'react-router-dom';

const UseAuths = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const tokenExpiry = localStorage.getItem('tokenExpiry');

  const checkAuth = useCallback(() => { // Use useCallback to memoize the function
    if (!token || !tokenExpiry || Date.now() > tokenExpiry) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('tokenExpiry');
      navigate('/login');
    } else {
      setLoading(false);
    }
  }, [token, tokenExpiry, navigate]);

  useEffect(() => {
    checkAuth();

    const interval = setInterval(() => {
      checkAuth();
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [checkAuth]); // Include checkAuth in the dependency array

  if (loading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default UseAuths;
