 
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Element, adminOnly, managerOnly, attendantOnly, ...rest }) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && userRole !== 'admin') {
    return <Navigate to="/" replace />;
  }

  if (managerOnly && userRole !== 'manager') {
    return <Navigate to="/" replace />;
  }

  if (attendantOnly && userRole !== 'attendant') {
    return <Navigate to="/" replace />;
  }

  return <Element {...rest} />;
};

export default ProtectedRoute;
