import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from './Table'; // Assume you have a Table component
import Navbar from './Navbar';

const CardTransactionsPage = () => {
  const { machineId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
   

  useEffect(() => {
    
    // Function to fetch transactions
    const fetchTransactions = async () => {
      setLoading(true);
      setError(null);

      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${API_URL}/machines/${machineId}/card-transactions`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
            
            setTransactions(data);
    
      } catch (error) {
        console.error('Error fetching transactions:', error);
    
            setError('Failed to fetch transactions');
        
        } finally {
     
            setLoading(false);
        
        }
    };

    fetchTransactions();

    // Set up interval for auto-refresh every 5 seconds  
    const intervalId = setInterval(fetchTransactions, 5000);

    // Cleanup function to clear interval when component unmounts
    return () => { 
        // isMounted = false; // Prevent state updates on unmount
         clearInterval(intervalId);
    };
  }, [machineId]);

  // Define columns based on your requirements
  const columns = [
    { Header: 'SN', accessor: 'id' },
    { Header: 'Card ID', accessor: 'cardId' },
    { Header: 'Card Type', accessor: 'cardType'}, 
    { Header: 'Fisrt Name', accessor: 'cardholderFirstName'},
    { Header: 'Last Name', accessor: 'cardholderLastName'},
    { Header: 'Price', accessor: 'price' },
    { Header: 'Volume', accessor: 'volume' },
    { Header: 'Water Type', accessor: 'waterType' },
    { Header: 'Date', accessor:  'time'},
     
    
  ];

  return (
    <div> 
        <Navbar />
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-semibold mb-4">Card-Based Transactions</h1>
      {loading &&
       <p>
        {/* Loading... */}
        </p>}
      {error && <p className="text-red-500">{error}</p>}
      <Table columns={columns} data={transactions} />
    </div>
    </div>
  );
};

export default CardTransactionsPage;
