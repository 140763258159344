import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

function MachineUsage() {
  const [espDevices, setEspDevices] = useState([]);
  const [attendantInfo, setAttendantInfo] = useState({}); // Store attendant info for each device

  useEffect(() => {
    const fetchUserData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        // Fetch ESP devices
        const espResponse = await fetch(`${API_URL}/user/esp32-ids`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!espResponse.ok) {
          const errorText = await espResponse.text();
          console.error('Failed to fetch ESP devices:', errorText);
          return;
        }

        const devices = await espResponse.json();
        setEspDevices(devices);

        // Fetch attendant info for each device
        devices.forEach(async (device) => {
          try {
            const attendantResponse = await fetch(`${API_URL}/attendant/${device.esp32Id}/info`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (attendantResponse.ok) {
              const attendantData = await attendantResponse.json();
              setAttendantInfo((prevState) => ({
                ...prevState,
                [device.esp32Id]: attendantData, // Store attendant data by device ID
              }));
            } else {
              // If attendant not found, mark as "Not Assigned"
              setAttendantInfo((prevState) => ({
                ...prevState,
                [device.esp32Id]: null, // No attendant assigned
              }));
            }
          } catch (error) {
            console.error('Error fetching attendant data:', error);
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserData();
  }, []); // Only run once on component mount

  return (
    <div>
      <Navbar />
      <div className="p-8 bg-gray-100 min-h-screen">
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 hover:shadow-xl transition-shadow duration-200 hover:border-blue-400">
            <h2 className="text-2xl text-black font-semibold mb-4">Manage Devices</h2>
            <p className="text-sml text-green-700 font-semibold mb-2">Select on the machine block to edit it setting.</p>
            <ul className="space-y-4">
              {espDevices.length > 0 ? (
                espDevices.map((device) => (
                  <li key={device.esp32Id} className="flex flex-col space-y-2">
                    <Link
                      to={`/device/${device.esp32Id}`}
                      className="block bg-gradient-to-r from-blue-200 via-gray-300 to-green-300 p-4 rounded-lg hover:bg-gradient-to-r hover:from-blue-400 hover:via-gray-400 hover:to-teal-300 hover:text-white transition-colors duration-200"
                    >
                      <div className="text-blue-600">
                        <strong>ID:</strong> {device.esp32Id}
                      </div>
                      <div className="text-green-600 mt-2">
                        <strong>Location:</strong> {device.machine_location}
                      </div>
                      {attendantInfo[device.esp32Id] ? (
                        <div className="text-amber-950 mt-2">
                          <strong>Attendant:</strong> {attendantInfo[device.esp32Id].firstName} {attendantInfo[device.esp32Id].lastName}
                        </div>
                      ) : (
                        <div className="text-red-600 mt-2">
                          <strong>Attendant:</strong> Not Assigned
                        </div>
                      )}
                    </Link>
                  </li>
                ))
              ) : (
                <p className="text-gray-700 text-center py-4">No ESP devices assigned</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MachineUsage;
