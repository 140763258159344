

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaAngellist, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isAttendant, setIsAttendant] = useState(false);
  const [isManagementOpen, setIsManagementOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [, setEspDevices] = useState([]);

  useEffect(() => {
    // Retrieve role from localStorage
    const role = localStorage.getItem('role');
    setIsAdmin(role === 'admin');
    setIsUser(role === 'manager');
    setIsAttendant(role === 'attendant');
  }, []);

  useEffect(() => {
    // Close sidebar when clicking outside
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsManagementOpen(false); // Also close the management dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsManagementOpen(false); // Close management dropdown when toggling sidebar
  };

  const toggleManagementMenu = () => {
    setIsManagementOpen(!isManagementOpen);
  };

  useEffect(() => {
    const fetchespData = async () => {
      
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const espResponse = await fetch(`${API_URL}/user/esp32-ids`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!espResponse.ok) {
          const errorText = await espResponse.text();
          console.error('Failed to fetch attendant info:', errorText);
          return;
        }

        const devices = await espResponse.json();
        setEspDevices(devices);

      } catch (error) {
        console.error('Error fetching esp data:', error);
      }
    };

    fetchespData();
  }, []);


  return (
    <nav className="bg-gradient-to-r from-blue-500 via-cyan-600 to-gray-700 p-6 shadow-2xl">
      <div className="container mx-auto flex justify-between items-center">

        {/* Hamburger Menu Icon on the Left */}
        <div className="flex items-center">
          <button onClick={toggleMenu} className="text-white pr-10 text-2xl focus:outline-none lg:hidden">
            {isOpen ? <FaAngleLeft /> : <FaAngellist />}
          </button>
          <h1 className="text-white text-2xl font-bold ml-4">Oak-Tech Solutions</h1>
        </div>

        {/* Sidebar for small screens */}
        <div
          ref={sidebarRef}
          className={`fixed top-0 left-0 h-full w-40 bg-gradient-to-br from-cyan-700 to-blue-700 z-50 transform ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out lg:hidden`}
        >
          <div className="flex justify-between items-center p-4">
            <h2 className="text-white text-2xl font-bold">Menu</h2>
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              <FaAngleLeft />
            </button>
          </div>

          {/* Navbar Links */}
          <div className="flex flex-col mt-4 space-y-2 text-lg text-gray-200">
            {isAttendant && (
              <>
              <Link to="/att/manager/dashboard" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                Add Card
              </Link>

              <Link to="/att/manager/float" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                Request Float
              </Link>
              </>

            )}
            {isUser && (
              <>
                <Link to="/manager/dashboard" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Dashboard
                </Link>
                <Link to="/manager/approv/float" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Approve Float
                </Link>
                <Link to="/manager/card/info" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Card Info
                </Link>
                <Link to="/manager/machine/usage" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Machine Info
                </Link>
                <div className="relative group">
                  <button 
                      className=" text-left hover:bg-gray-700 p-2" 
                      onClick={toggleManagementMenu}
                    >
                    Management
                    {isManagementOpen ? <FaAngleLeft className="ml-4 inline" /> : <FaAngleRight className="ml-4 inline " />}
                  </button>
                  {isManagementOpen && (
                    <>
                      <div
                      className='absolute top-0 left-full ml-1 h-full w-48 bg-gray-800 rounded-2xl text-gray-200 transition-transform duration-300 ease-in-out   opacity-100'
                      style={{ zIndex: 100 }} // Ensure sidebar is above other elements
                     >
                     <Link to="/manager/attendant/register" className="block px-4 py-2 bg-gray-400 rounded-t-2xl hover:bg-gray-900">Add Attendant</Link>
                     <Link to="/manager/attendant/remove" className="block px-4 py-2  bg-gray-400 hover:bg-gray-900">Remove Attendant</Link>                 
                    </div>
                  
                  </>
                    )}
                    </div>

                <Link to="/manager/profile/acc" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  My Profile
                   
                </Link>
              </>
            )}
            {isAdmin && (
              <>
                <Link to="/maindashboard" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Dashboard
                </Link>
                <Link to="/admin/users" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Users
                </Link>
                <Link to="/register" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Create Users
                </Link>
                <Link to="/add-esp32" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Add Devices
                </Link>
                <Link to="/admin/profile" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Profile
                </Link>
                <Link to="/admin/settings" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
                  Settings
                </Link>
              </>
            )}
            <Link to="/logout" className="hover:bg-gray-700 p-2" onClick={toggleMenu}>
              Logout
            </Link>
          </div>
        </div>

        {/* Normal links for larger screens */}
        <div className="hidden lg:flex items-center space-x-6 text-white">
          {isAdmin && (
            <>
              <Link to="/maindashboard" className="hover:bg-gray-700 rounded-md px-3">Dashboard</Link>
              <Link to="/admin/users" className="hover:bg-gray-700 rounded-md px-3">Users</Link>
              <Link to="/register" className="hover:bg-gray-700 rounded-md px-3">Create Users</Link>
              <Link to="/add-esp32" className="hover:bg-gray-700 rounded-md px-3">Add Devices</Link>
              <Link to="/admin/profile" className="hover:bg-gray-700 rounded-md px-3">Profile</Link>
              <Link to="/admin/settings" className="hover:bg-gray-700 rounded-md px-3">Settings</Link>
            </>
          )}
          {isUser && (
            <>
              <Link to="/manager/dashboard" className="hover:bg-gray-700 rounded-md px-3">Dashboard</Link>
              <Link to="/manager/card/info" className="hover:bg-gray-700 rounded-md px-3">Card Info</Link>
              <Link to="/manager/machine/usage" className="hover:bg-gray-700 rounded-md px-3">Machine Info</Link>
              <Link to="/manager/approv/float" className="hover:bg-gray-700 rounded-md px-3">Approve Float</Link>
              <div className="relative">
                <button 
                  className="text-white hover:bg-gray-700 rounded-md px-3" 
                  onClick={toggleManagementMenu}
                >
                  Management
                  {isManagementOpen ? <FaAngleUp className="ml-2 inline" /> : <FaAngleDown className="ml-2 inline" />}
                </button>
                <div
                  className={`absolute left-0 mt-2 w-48 bg-gray-800 rounded-2xl text-gray-200 transition-transform duration-300 ease-in-out ${isManagementOpen ? 'translate-y-0 opacity-100' : '-translate-y-2 opacity-0'}`}
                >
                  <Link to="/manager/attendant/register" className="block px-4 py-2 rounded-2xl hover:bg-gray-900">Add Attendant</Link>
                  <Link to="/manager/attendant/remove" className="block px-4 py-2 rounded-2xl hover:bg-gray-900">Remove Attendant</Link>
                 
                </div>
              </div>
              <Link to="/manager/profile/acc" className="hover:bg-gray-700 rounded-md px-3">Profile</Link>
            </>
          )}

            {isAttendant && (
              <>
              <Link to="/att/manager/dashboard"className="hover:bg-gray-700 rounded-md px-3">Add Card</Link>

              <Link to="/att/manager/float"className="hover:bg-gray-700 rounded-md px-3">Request Float</Link>
              </>

            )}
          <Link to="/logout" className="hover:bg-gray-700 rounded-md px-3">LogOut</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
