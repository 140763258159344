import React from 'react';
import Wcredentials from './Wcredentials';
import TariffsForm from './TariffsForm';
import Navbar from './Navbar';

function DeviceSetting() {
  return (
    <div> 
      <Navbar />

      <div className="max-w-3xl mx-auto mt-10 p-5">
      <h1 className="text-3xl font-bold text-center mb-6">Device Settings</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
       
        <div className='bg-slate-300 border-r-lime-900 '>
          <h2 className="text-2xl font-semibold m-4 text-center ">Wi-Fi Credentials</h2>
          <Wcredentials />
        </div>

        <div className='bg-slate-400'>
          <h2 className="text-2xl font-semibold m-4 text-center">Tariffs Management</h2>
          <TariffsForm />
        </div>
      </div>
    </div>
    </div>
  );
}

export default DeviceSetting;
