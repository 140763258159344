import React from 'react'
import Navbar from './Navbar'
import CardRegister from './CardRegister'

function AttendantDashboard() {
  return (
    <div>
        <Navbar />
        <CardRegister />
    </div>
  )
}

export default AttendantDashboard