import React from 'react';
import CardInfoSummary from './CardInfoSummary';
import Navbar from './Navbar';

function CardInfo() {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Navbar Section */}
      <Navbar />

      {/* Main Content Section */}
          <div className="bg-white rounded-lg shadow-lg ">
          {/* CardInfoSummary Component */}
          <CardInfoSummary />
       </div>
    </div>
  );
}

export default CardInfo;
