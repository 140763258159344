 
// export default Table;
import React, { useState } from 'react';
import classNames from 'classnames';

const Table = ({ columns, data }) => {
  const [filter, setFilter] = useState('today');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  // Filter function
  const filteredData = data.filter(item => {
    const today = new Date();
    const itemDate = new Date(item.time);
    return filter === 'today'
      ? itemDate.toDateString() === today.toDateString()
      : itemDate < today;
  });

  // Sorting function
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      let direction = 'asc';
      if (prevConfig.key === key && prevConfig.direction === 'asc') {
        direction = 'desc';
      }
      return { key, direction };
    });
  };

  return (
    <div className="overflow-x-auto">
      {/* Select Data Dropdown */}
      <div className="mb-4">
        <label htmlFor="dataFilter" className="block text-sm font-medium text-gray-700">Filter Data:</label>
        <select
          id="dataFilter"
          value={filter}
          onChange={handleFilterChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="today">Today's Transactions</option>
          <option value="older">Older Transactions</option>
        </select>
      </div>

      {/* Table */}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer  transition-colors duration-200 hover:bg-gray-200"
                onClick={() => handleSort(column.accessor)}
              >
                {column.Header}
                {sortConfig.key === column.accessor && (
                  <span className={classNames('ml-2', {
                    'text-gray-400': sortConfig.direction === 'asc',
                    'text-gray-900': sortConfig.direction === 'desc',
                  })}>
                    {sortConfig.direction === 'asc' ? '▲' : '▼'}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedData.map((item) => (
            <tr
              key={item.id}
              className="hover:bg-gray-100 transition-colors duration-200"
            >
              {columns.map((column, index) => (
                <td key={index} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {/* Check if it's a regular column accessor */}
                  {column.accessor && column.accessor !== 'time' ? (
                    item[column.accessor] 
                  ) : column.Cell ? (
                    column.Cell({ row: item })
                  ) : null}

                  {/* Special case for time formatting */}
                  {column.accessor === 'time' && (
                    <span>{new Date(item.time).toLocaleString()}</span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
