import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';

const AttendantFloat = () => {
  const [floatAmount, setFloatAmount] = useState('');
  const [message, setMessage] = useState('');
  const [floatRequests, setFloatRequests] = useState([]);

  // Function to handle float request submission
  const handleFloatRequest = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_URL}/attendant/request-float`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ amount: floatAmount }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        setMessage(`Error: ${errorText}`);
        return;
      }

      setMessage('Float request submitted successfully');
      setFloatAmount('');
      fetchFloatRequests(); // Refresh the list after submitting a new request
    } catch (error) {
      setMessage('Error submitting float request');
      console.error('Error:', error);
    }
  };

  // Function to fetch the attendant's float requests
  const fetchFloatRequests = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_URL}/attendant/float-requests`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error fetching float requests:', errorText);
        return;
      }

      const data = await response.json();
      setFloatRequests(data);
    } catch (error) {
      console.error('Error fetching float requests:', error);
    }
  };

  // Fetch float requests when the component loads
  useEffect(() => {
    fetchFloatRequests();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="p-8">
        <h1 className="text-2xl font-bold mb-4">Attendant Dashboard</h1>

        {/* Form to request a float */}
        <div className="mb-4">
          <label className="block text-gray-700">Request Float Amount:</label>
          <input
            type="number"
            value={floatAmount}
            onChange={(e) => setFloatAmount(e.target.value)}
            className="border rounded px-3 py-2"
          />
        </div>
        <button
          onClick={handleFloatRequest}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Request Float
        </button>
        {message && <p className="mt-4">{message}</p>}

        {/* List of float requests */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Float Request History</h2>
          {floatRequests.length > 0 ? (
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2">Requested Amount</th>
                  <th className="py-2">Approved Amount</th>
                  <th className="py-2">Status</th>
                  <th className="py-2">Requested At</th>
                  <th className="py-2">Approved At</th>
                </tr>
              </thead>
              <tbody>
                {floatRequests.map((request) => (
                  <tr key={request.requestId}>
                    <td className="border px-4 py-2">{request.requestedAmount}</td>
                    <td className="border px-4 py-2">{request.approvedAmount || 'N/A'}</td>
                    <td className="border px-4 py-2">{request.status}</td>
                    <td className="border px-4 py-2">{new Date(request.requestedAt).toLocaleString()}</td>
                    <td className="border px-4 py-2">{request.approvedAt ? new Date(request.approvedAt).toLocaleString() : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No float requests found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttendantFloat;
