import React, { useState } from 'react';
// import NavBar from './NavBar';

function CardRegister() {
  const [formData, setFormData] = useState({
    card_id: '',
    card_holder_first_name: '',
    card_holder_last_name: '',
    phoneNumber: '',
    card_balance: '',
    card_type: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
  
      const response = await fetch(`${API_URL}/att/add/user/card`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
  
      if (response.status === 409) {
        // Handle card already registered
        alert('Card is already registered.');
      } else if (response.ok) {
        alert(`User added successfully. ID: ${result.id}`);
        // Optionally, clear the form or redirect the user
        setFormData({
          card_id: '',
          card_holder_first_name: '',
          card_holder_last_name: '',
          phoneNumber: '',
          card_balance: '',
          card_type: '',
        });
      } else {
        alert('Failed to add user: ' + result.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while adding the user.');
    }
  };
  

  return (
    <div>
    {/* <NavBar /> */}
    <div className="container mx-auto p-4">
  
      <h1 className="text-2xl font-bold mb-4">Register New User</h1>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label className="block text-gray-700">Card ID</label>
          <input
            type="text"
            name="card_id"
            value={formData.card_id}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">First Name</label>
          <input
            type="text"
            name="card_holder_first_name"
            value={formData.card_holder_first_name}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Last Name</label>
          <input
            type="text"
            name="card_holder_last_name"
            value={formData.card_holder_last_name}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Mobile No</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700">Balance</label>
          <input
            type="number"
            name="card_balance"
            value={formData.card_balance}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
            step="0.01"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Card Type</label>
          <input
            type="text"
            name="card_type"
            value={formData.card_type}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Register
        </button>
      </form>
    </div>
    </div>
  );
}

export default  CardRegister;
