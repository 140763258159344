import React, { useState, useEffect } from 'react';
import MachineSummary from './MachineSummary';

const MachineActivity = () => {
  const [machineSummaries, setMachineSummaries] = useState([]);
  const [monthlySummaries, setMonthlySummaries] = useState([]);
  const [loadingDaily, setLoadingDaily] = useState(true);
  const [loadingMonthly, setLoadingMonthly] = useState(true);
  const [date, setDate] = useState(() => new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const fetchDailyData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/user/machines/daily-summary?date=${date}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched Daily Machine Data:', data); // Log fetched data for debugging
          setMachineSummaries(data.dailyData || []);
        } else {
          throw new Error('Failed to fetch daily machine data');
        }
      } catch (error) {
        console.error('Error fetching daily data:', error);
      } finally {
        setLoadingDaily(false);
      }
    };

    const fetchMonthlyData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/user/machines/monthly-summary?date=${date}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched Monthly Machine Data:', data); // Log fetched data for debugging
          setMonthlySummaries(data.monthlyData || []);
        } else {
          throw new Error('Failed to fetch monthly machine data');
        }
      } catch (error) {
        console.error('Error fetching monthly data:', error);
      } finally {
        setLoadingMonthly(false);
      }
    };

    fetchDailyData();
    fetchMonthlyData();
  }, [date]);

  // Determine which data to display based on available summaries
  const dataToDisplay = machineSummaries.length > 0 ? machineSummaries : monthlySummaries;

  return (
    <div>
      <div className="machine-activity-page p-6 bg-gray-100 min-h-screen">
        <div className="date-filter mb-6">
          <label htmlFor="date" className="block text-gray-700 font-medium mb-2">Select Date:</label>
          <input 
            type="date" 
            value={date} 
            onChange={(e) => setDate(e.target.value)}  
            className="border border-gray-300 p-2 rounded-lg w-full md:w-1/3 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
        {(loadingDaily || loadingMonthly) ? (
          <div className="text-center text-gray-500">Loading...</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {dataToDisplay.length === 0 ? (
              <div className="text-red-500 text-center">No machines available for the selected date.</div>
            ) : (
              dataToDisplay.map((machine) => (
                <MachineSummary key={machine.machineId} machine={machine} monthlyData={monthlySummaries} />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MachineActivity;
