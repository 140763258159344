import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import AddESP32 from './AddESP32';
import ProtectedRoute from './ProtectedRoute';
import Logout from './Logout';
import Welcome from './Welcome';
import UserDashboard from './UserDashboard';
import AdminRegister from './AdminRegister';
import MainDashboard from './MainDashboard';
import UseAuths from './UseAuths';
import AttendantRegister from './AttendantRegister';
import AttendantDashboard from './AttendantDashboard';
import MachineActivity from './MachineActivity';
import UserProfile from './UserProfile';
import CardInfo from './CardInfo';
import CardInfoSummary from './CardInfoSummary';
import CardTransactionsPage from './CardTransactionsPage';
import CoinTransactionsPage from './CoinTransactionsPage';
import MachineUsage from './MachineUsage';
import RemoveAttendant from './RemoveAttendant';
import AssignESPId from './AssignESPId';
import DeviceSetting from './DeviceSetting';
import CardRegister from './CardRegister';
import AttendantFloat from './AttendantFloat';
import ManagerAprove from './ManagerAprove';
 

function App() {
    // useAuth();
  return (
    <Router>
      
      <div className="App">
        {/* <Navbar /> Uncomment this if you want to use Navbar */}
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/a" element={<AdminRegister />} />
          <Route path="/b" element = {<AssignESPId />} />
          <Route path='/ca' element= {<CardRegister />} />

          {/* Admin-only Protected Routes */}
          <Route path="/add-esp32" element={<UseAuths><ProtectedRoute element={AddESP32} adminOnly={true} /></UseAuths>} />
          <Route path="/register" element={<UseAuths><ProtectedRoute element={Register} adminOnly={true} /></UseAuths>} />
          <Route path="/maindashboard" element={<UseAuths><ProtectedRoute element={MainDashboard} adminOnly={true} /></UseAuths>} />



          {/* Manager-only Protected Routes */}
          <Route path="/manager/attendant/register" element={<UseAuths><ProtectedRoute element={AttendantRegister} managerOnly={true} /></UseAuths>} />          
          <Route path="/manager/dashboard" element={<UseAuths><ProtectedRoute element={UserDashboard} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/profile/acc" element={<UseAuths><ProtectedRoute element={UserProfile} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/machine/activity" element={<UseAuths><ProtectedRoute element={MachineActivity} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/card/info" element={<UseAuths><ProtectedRoute element={CardInfo} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/card/info/summary" element={<UseAuths><ProtectedRoute element={CardInfoSummary} managerOnly={true} /></UseAuths>} />
          <Route path="/machine/:machineId/card" element={<UseAuths><ProtectedRoute element={CardTransactionsPage} managerOnly={true} /></UseAuths>} />
          <Route path="/machine/:machineId/coin" element={<UseAuths><ProtectedRoute element={CoinTransactionsPage} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/machine/usage" element={<UseAuths><ProtectedRoute element={MachineUsage} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/attendant/remove" element={<UseAuths><ProtectedRoute element={RemoveAttendant} managerOnly={true} /></UseAuths>} />
          <Route path="/device/:espId" element={<UseAuths><ProtectedRoute element={DeviceSetting} managerOnly={true} /></UseAuths>} />
          <Route path="/manager/approv/float" element={<UseAuths><ProtectedRoute element={ManagerAprove} managerOnly={true} /></UseAuths>} />
       

          {/* Attendant-only Protected Routes */}
          <Route path="/att/manager/dashboard" element={<UseAuths><ProtectedRoute element={AttendantDashboard} attendantOnly={true} /></UseAuths>} />
          <Route path="/att/manager/float" element={<UseAuths><ProtectedRoute element={AttendantFloat} attendantOnly={true} /></UseAuths>} />

        
        </Routes>
      </div>
    </Router>
  );
}

export default App;
