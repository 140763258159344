import React from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the token from local storage (or wherever it's stored)
    localStorage.removeItem('token');

    // Redirect the user to the login page after logging out
    navigate('/login');
  };

  const handleCancel = () => {
    // Navigate to the previous page or home page if the user cancels
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full text-center">
        <h2 className="text-2xl font-bold mb-6">Logout</h2>
        <p className="mb-4">Are you sure you want to log out?</p>
        <div className="flex justify-between">
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-200"
          >
            Logout
          </button>
          <button
            onClick={handleCancel}
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Logout;
